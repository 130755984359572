import React, { useCallback, useContext, useEffect, useState } from "react";
import noop from "lodash/noop";
import { useHistory } from "react-router-dom";

import ConfirmModal from "../components/ConfirmModal";
import CopyActivationLinkModal from "../components/CopyActivationLinkModal";
import CopyJourneyModal from "../components/CopyJourneyModal";
import CreateContextualContentModal from "../components/Journey/CreateContextualContentModal";
import CreateContextualTriggerModal from "../components/Journey/CreateContextualTriggerModal";
import EditCompanyModal from "../components/EditCompanyModal";
import EditMaterialModal from "../components/Journey/EditMaterialModal";
import EditStepModal from "../components/Journey/EditStepModal";
import EditScheduledSlotModal from "../components/Journey/EditScheduledSlotModal";
import DeleteJourneyModal from "../components/DeleteJourneyModal";
import EditSpecialLinkModal from "../components/EditSpecialLinkModal";
import EditTipGroupModal from "../components/EditTipGroupModal";
import EditUserMetaModal from "../components/EditUserMetaModal";
import SemanticsWithEmptyCTALinkModal from "../components/SemanticsWithEmptyCTALinkModal";
import AssociateTipModal from "../components/AssociateTipModal";
import SemanticTemplatesGalleryModal from "../components/Interaction/SemanticTemplatesGalleryModal";
import CopyWelfareModal from "../components/CopyWelfareModal";
import SpecificUsersModal from "../components/SpecificUsersModal";
import CopyFlowModal from "../components/Journey/CopyFlowModal";

const modalTypes = {
  AssociateTip: "AssociateTip",
  Confirm: "Confirm",
  CopyActivationLink: "CopyActivationLink",
  CopyFlow: "CopyFlowModal",
  CopyJourney: "CopyJourney",
  CopyWelfare: "CopyWelfare",
  CreateBrowserTrigger: "CreateBrowserTrigger",
  CreateContextualContent: "CreateContextualContent",
  CreateContextualTrigger: "CreateContextualTrigger",
  CreateTrigger: "CreateTrigger",
  DeleteJourney: "DeleteJourney",
  EditCompany: "EditCompany",
  EditMaterial: "EditMaterial",
  EditScheduledSlot: "EditScheduledSlot",
  EditSpecialLink: "EditSpecialLink",
  EditStep: "EditStep",
  EditTipGroup: "EditTipGroup",
  EditUserMeta: "EditUserMeta",
  SemanticTemplatesGalleryModal: "SemanticTemplatesGalleryModal",
  SemanticsWithEmptyCTALink: "SemanticsWithEmptyCTALink",
  SpecificUsers: "SpecificUsers",
};

const modals = {
  [modalTypes.CopyActivationLink]: CopyActivationLinkModal,
  [modalTypes.CopyJourney]: CopyJourneyModal,
  [modalTypes.CreateContextualContent]: CreateContextualContentModal,
  [modalTypes.CreateContextualTrigger]: CreateContextualTriggerModal,
  [modalTypes.EditMaterial]: EditMaterialModal,
  [modalTypes.EditStep]: EditStepModal,
  [modalTypes.Confirm]: ConfirmModal,
  [modalTypes.EditCompany]: EditCompanyModal,
  [modalTypes.DeleteJourney]: DeleteJourneyModal,
  [modalTypes.EditSpecialLink]: EditSpecialLinkModal,
  [modalTypes.EditTipGroup]: EditTipGroupModal,
  [modalTypes.EditUserMeta]: EditUserMetaModal,
  [modalTypes.SemanticsWithEmptyCTALink]: SemanticsWithEmptyCTALinkModal,
  [modalTypes.AssociateTip]: AssociateTipModal,
  [modalTypes.EditScheduledSlot]: EditScheduledSlotModal,
  [modalTypes.CopyFlow]: CopyFlowModal,
  [modalTypes.SemanticTemplatePreview]: SemanticTemplatesGalleryModal,
  [modalTypes.CopyWelfare]: CopyWelfareModal,
  [modalTypes.SpecificUsers]: SpecificUsersModal,
};

const ModalsContext = React.createContext({
  closeModal: noop,
  modalTypes: modalTypes,
  openConfirmation: noop,
  openModal: noop,
});

const useModals = () => useContext(ModalsContext);

const ModalsProvider = ({ children }) => {
  const [modal, setModal] = useState(null);
  const [modal2, setModal2] = useState(null);
  const [modalProps, setModalProps] = useState(null);
  const [modal2Props, setModal2Props] = useState(null);
  const history = useHistory();

  useEffect(() => {
    history.listen(() => {
      setModal(null);
      setModal2(null);
      setModalProps(null);
      setModal2Props(null);
    });
  }, [history]);

  const handleOpen = useCallback((modalToOpen, props) => {
    setModal(modalToOpen);
    setModalProps(props);
  }, []);

  const handleOpen2 = useCallback((modalToOpen, props) => {
    setModal2(modalToOpen);
    setModal2Props(props);
  }, []);

  const handleOpenConfirmation = useCallback(
    ({
      title = "Are you sure?",
      text = "",
      confirmCTATitle = "Confirm",
      closeCTATitle = "Cancel",
      ...props
    } = {}) => {
      setModal2(modalTypes.Confirm);
      setModal2Props({
        closeCTATitle,
        confirmCTATitle,
        text,
        title,
        ...props,
      });
    },
    []
  );

  const handleClose = useCallback(
    (...args) => {
      if (typeof modalProps?.onClose === "function") {
        modalProps.onClose(...args);
      }

      setModal(null);
    },
    [modalProps]
  );

  const handleClose2 = useCallback(
    (...args) => {
      if (typeof modal2Props?.onClose === "function") {
        modal2Props.onClose(...args);
      }

      setModal2(null);
    },
    [modal2Props]
  );

  const Modal = modals[modal];
  const Modal2 = modals[modal2];

  return (
    <ModalsContext.Provider
      value={{
        closeModal: handleClose,
        modalTypes,
        openConfirmation: handleOpenConfirmation,
        openModal: handleOpen,
        openModal2: handleOpen2,
      }}
    >
      {Modal && <Modal {...modalProps} onClose={handleClose} />}
      {Modal2 && <Modal2 {...modal2Props} onClose={handleClose2} />}
      {children}
    </ModalsContext.Provider>
  );
};

export { useModals, ModalsProvider };
