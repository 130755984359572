/* eslint-disable prettier/prettier */
import { Grid, TextField, Box, Typography, Button } from "@material-ui/core";
import InfoTooltip from "../InfoTooltip";
import ImageUpload from "../ImageUpload";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAppContext } from "../../api/AppContext";
import { getFieldProps } from "../utils";

const Tools = ({formik}) => {
 
  const handleInputChange = (name, value) => {
    formik.setFieldValue(name, value);
  };

  return (
    <Box
      alignContent="center"
      boxSizing="border-box"
      display="flex"
      flexDirection="column"
      height="100%"
      p={4}
      width="100%"
    >
      <Grid container spacing={2}>
        <Grid container item justifyContent="center" xs={12}>
          <Grid item xs={4}>
            <TextField
              fullWidth
              required
              InputLabelProps={{ shrink: true }}
              label="Name"
              margin="none"
              value={formik.values.toolname}
              variant="outlined"
              onChange={(event) =>
                handleInputChange("toolname", event.target.value)
              }
            />
          </Grid>
        </Grid>
        <Grid container item justifyContent="center" xs={12}>
          <Grid item>
            <Typography
              color="textSecondary"
              style={{ padding: "0px 4px 0px 0px" }}
              variant="caption"
            >
              Icon
            </Typography>
            <InfoTooltip
              style={{ padding: "0px 0px 0px 4px" }}
              text="Upload .jpg or .png files. The uploaded picture will be displayed with 960px width and 320px height. In case of size mismatch, the picture will be scaled horizontally to 960px and cropped vertically (a central stripe of 320px height will be taken)"
              title="Icon Dimensions"
            />
            <ImageUpload
                    styles={{ width: "100%" }}
                    {...getFieldProps(formik, {
                      name: "toolicon",
                    })}
                    customPathInfo={{ cid: "67df6292-c0a7-4831-a94e-61b6ea73c990" }}
                    onChange={({ url }) => {
                      formik.setFieldValue("toolicon", url);
                      formik.setFieldTouched(
                        "toolicon",
                        true
                      );
                    }}
                  />             
          </Grid>
        </Grid>

        <Grid container item justifyContent="space-around" xs={12}>
          <Grid>
          <Typography
              color="textSecondary"
              style={{ padding: "0px 4px 0px 0px" }}
              variant="caption"
            >
              Color Top
            </Typography>
            <input type="color" value={formik.values.colortop}  onChange={(event) =>
                handleInputChange("colortop", event.target.value)
            }/>

          </Grid>
          <Grid>
         
            <input type="color" value={formik.values.colorbottom}  onChange={(event) =>
                handleInputChange("colorbottom", event.target.value)}/>
                 <Typography
              color="textSecondary"
              style={{ padding: "0px 0px 0px 4px" }}
              variant="caption"
            >
              Color Bottom
            </Typography>
          </Grid>

        </Grid>
        <Grid container item justifyContent="center" xs={12}>
          <Button
            color="primary"
            type="submit"
            variant="contained"
            onClick={formik.handleSubmit}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

const ToolContainer = () => {
  const {updateTool} = useAppContext();
  const { id } = useParams();
  const history = useHistory();
  const location = useLocation();
  const [configurationEntity, setConfigurationEntity] = useState(null);

  useEffect(() => {
    const { rowData } = location.state || {};
    if (rowData) {
      setConfigurationEntity({ ...rowData, id });
    }
  }, [id, location.state]);
  const validationSchema = Yup.object({
    colorbottom: Yup.string().required("Color Bottom is required"),
    colortop: Yup.string().required("Color Top is required"),
    toolicon: Yup.string().required("Icon is required"),
    toolname: Yup.string().required("Name is required"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: configurationEntity || {
      colorbottom: "",
      colortop: "",
      isNew:true,
      toolicon: "",
      toolname: "",
    },
    onSubmit: async (values) => {
      await updateTool(values)
      history.push("/copilot/tools");
    },
    validationSchema: validationSchema,
    validator: () => ({}),
  });


  if (!formik.values) {
    return (
      <Box
        alignItems="center"
        display="flex"
        justifyContent="center"
        width="100%"
      >
        <Typography variant="subtitle1">
          Tool is not found 😢
        </Typography>
      </Box>
    );
  }

  return <Tools formik={formik} />;
};

export default ToolContainer;