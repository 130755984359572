/* eslint-disable prettier/prettier */
import { Grid, TextField, Box, Button, Typography } from "@material-ui/core";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import * as Yup from "yup";
import { useAppContext } from "../../api/AppContext";

const Tags = ({ formik }) => {
  const handleInputChange = (name, value) => {
    formik.setFieldValue(name, value);
  };

  return (
    <Box
      alignContent={"center"}
      boxSizing="border-box"
      display="flex"
      flexDirection="column"
      height="100%"
      p={4}
      width="100%"
    >
      <Grid container spacing={2}>
        <Grid container item justifyContent="center" xs={12}>
          <Grid item xs={4}>
            <TextField
              fullWidth
              required
              InputLabelProps={{ shrink: true }}
              error={formik.touched.tagname && Boolean(formik.errors.tagname)}
              helperText={formik.touched.tagname && formik.errors.tagname}
              label="Name"
              margin="none"
              value={formik.values.tagname}
              variant="outlined"
              onChange={(event) =>
                handleInputChange("tagname", event.target.value)
              }
            />
          </Grid>
        </Grid>
        <Grid container item justifyContent="center" xs={12}>
          <Grid item>
            <Typography align="center" color="textSecondary">
              Color
            </Typography>
            <input
              type="color"
              value={formik.values.tagcolor}
              onChange={(event) =>
                handleInputChange("tagcolor", event.target.value)
              }
            />
          </Grid>
        </Grid>
        <Grid container item justifyContent="center" xs={12}>
          <Button
            color="primary"
            type="submit"
            variant="contained"
            onClick={formik.handleSubmit}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

const TagsContainer = () => {
  const { updateTag } = useAppContext();
  const { id } = useParams();
  const history = useHistory();
  const location = useLocation();
  const [configurationEntity, setConfigurationEntity] = useState(null);
  const { rowData } = location.state || {};
  
  useEffect(() => {
    if (rowData) {
      setConfigurationEntity({ ...rowData, id });
    }
  }, [location.state, id, rowData]);

  const validationSchema = Yup.object({
    tagcolor: Yup.string().required("Color is required"),
    tagname: Yup.string().required("Name is required"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: configurationEntity || {
      isNew: true,
      tagcolor: "",
      tagname: "",
    },
    onSubmit: async (values) => {
      await updateTag({
        ...values,
      });
      //console.log("values" + JSON.stringify(values));
      history.push("/copilot/tags");
    },
    validationSchema: validationSchema,
    validator: () => ({}),
  });

  if (!rowData && !formik.values.isNew) {
    return (
      <Box alignItems="center" display="flex" justifyContent="center" width="100%">
        <Typography variant="subtitle1">Tag is not found 😢</Typography>
      </Box>
    );
  }

  return <Tags formik={formik} />;
};

export default TagsContainer;
