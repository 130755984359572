/* eslint-disable prettier/prettier */
import { Button, Box } from "@material-ui/core";
import { useState, useEffect, useRef, useCallback, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Table from "../shared/Table";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import { useAppContext } from "../../api/AppContext";
import { useModals } from "../../hooks/useModals";

const CopilotTable = () => {
  const {
    prompts,
    fetchPrompts,
    tools,
    fetchTools,
    tags,
    fetchTags,
    deletePrompt,
    deleteTool,
    deleteTag,
  } = useAppContext();

  const [data, setData] = useState([]);
  const location = useLocation();
  const history = useHistory();
  const hasFetchedDataRef = useRef({});
  const ref= useRef();
  const modals = useModals();

  const getPathSpecificColumns = (path) => {
    switch (path) {
      case "/copilot/prompt":
        return [
          { field: "title", title: "Title", type: "string" },
          { field: "description", title: "Description", type: "string" },
          { field: "prompttext", title: "Prompt", type: "string" },
          { field: "toolname", title: "Tool" },
          {
            field: "tags",
            render: (rowData) => (rowData?.tags ? rowData.tags.join(", ") : "No Tags"),
            title: "Tags",
          },
          { field: "isfromtypeform", title: "isFromTypeform" },
        ];
      case "/copilot/tools":
        return [
          { field: "toolname", title: "Tool Name" },
          { field: "colortop", title: "Color Top" },
          { field: "colorbottom", title: "Color Bottom" },
        ];
      case "/copilot/tags":
        return [
          { field: "tagname", title: "Name" },
          { field: "tagcolor", title: "Color" },
        ];
      default:
        return [];
    }
  };

  const columns = useMemo(() => getPathSpecificColumns(location.pathname), [location.pathname]);

  const getPathSpecificData = useCallback(async (path) => {
    switch (path) {
      case "/copilot/prompt":
        if (prompts.length === 0) await fetchPrompts();
        return prompts;
      case "/copilot/tools":
        if (tools.length === 0) await fetchTools();
        return tools;
      case "/copilot/tags":
        if (tags.length === 0) await fetchTags();
        return tags;
      default:
        return [];
    }
  }, [prompts, tools, tags, fetchPrompts, fetchTools, fetchTags]);

  const fetchData = useCallback(async () => {
    const path = location.pathname;
    if (!hasFetchedDataRef.current[path]) {
      let newData = await getPathSpecificData(path);
      if (JSON.stringify(data)!== JSON.stringify(newData)) {
        setData(newData);
        hasFetchedDataRef.current[path] = true;
      }
    }
  }, [getPathSpecificData, location.pathname, data]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);


  const onRowDelete = useCallback(async (values) => {
    const { id } = values;
    switch (location.pathname) {
      case "/copilot/prompt":
        await deletePrompt({ id });
        break;
      case "/copilot/tools":
        modals.openConfirmation({
          closeCTATitle: "Go back",
          confirmCTATitle: "Delete",
          text: "All the prompts associated with this tool will be deleted automatically.",
          onConfirm: async () => {
            await deleteTool({ id });
          }})
        break;
      case "/copilot/tags":
        modals.openConfirmation({
          closeCTATitle: "Go back",
          confirmCTATitle: "Delete",
          text: "All the prompts which have only this tag will be deleted automatically and it will be removed from the prompts that have multiple tags.",
          onConfirm: async () => {
            await deleteTag({ id });
          }})
        break;
      default:
        break;
    }
    hasFetchedDataRef.current[location.pathname] = false;
    fetchData();
  }, [location.pathname, fetchData, deletePrompt, deleteTool, deleteTag]);

  const editable = useMemo(() => ({ onRowDelete }), [onRowDelete]);

  return (
    <Box
      boxSizing="border-box"
      display="flex"
      flexDirection="column"
      height="100%"
      p={4}
      width="100%"
    >
      <Table
        actions={[
          {
            icon: (props) => <EditIcon {...props} color="action" />,
            onClick: (event, rowData) => {
              history.push(`${location.pathname}/${rowData.id}`, {
                isNew: false,
                rowData,
              });
            },
            tooltip: "Edit",
          },
        ]}
        columns={columns}
        data={data}
        editable={editable}
        refetch={fetchData}
        tableActions={[
          <Button
            key="add"
            color="primary"
            endIcon={<AddIcon />}
            variant="contained"
            onClick={() => {
              history.push(`${location.pathname}/new`, { isNew: true });
            }}
          >
            Create new {location.pathname.includes("prompt") ? "prompt" : location.pathname.includes("tool") ? "tool" : "tag"}
          </Button>,
        ]}
        tableRef={ref}
        title={
          location.pathname.includes("prompt")
            ? "Prompts"
            : location.pathname.includes("tool")
            ? "Tools"
            : "Tags"
        }
      />
    </Box>
  );
};

export default CopilotTable;
