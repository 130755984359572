/* eslint-disable prettier/prettier */
import {
  Grid,
  TextField,
  Box,
  MenuItem,
  Button,
  FormControlLabel,
  Checkbox,
  Typography,
} from "@material-ui/core";
import { useFormik } from "formik";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { useAppContext } from "../../api/AppContext";

const Prompt = ({ formik, tags, tools }) => {
  const handleInputChange = (name, value) => {
    formik.setFieldValue(name, value);
  };

  return (
    <>
      <Box
        boxSizing="border-box"
        component="form"
        display="flex"
        flexDirection="column"
        p={4}
        width="100%"
      >
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                InputLabelProps={{ shrink: true }}
                error={formik.touched.title && Boolean(formik.errors.title)}
                helperText={formik.touched.title && formik.errors.title}
                label="Title"
                margin="none"
                value={formik.values.title}
                variant="outlined"
                onChange={(event) =>
                  handleInputChange("title", event.target.value)
                }
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                InputLabelProps={{ shrink: true }}
                error={
                  formik.touched.description &&
                  Boolean(formik.errors.description)
                }
                helperText={
                  formik.touched.description && formik.errors.description
                }
                label="Description"
                margin="none"
                value={formik.values.description}
                variant="outlined"
                onChange={(event) =>
                  handleInputChange("description", event.target.value)
                }
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                multiline
                InputLabelProps={{ shrink: true }}
                error={
                  formik.touched.prompttext && Boolean(formik.errors.prompttext)
                }
                helperText={
                  formik.touched.prompttext && formik.errors.prompttext
                }
                label="Prompt"
                margin="none"
                value={formik.values.prompttext}
                variant="outlined"
                onChange={(event) =>
                  handleInputChange("prompttext", event.target.value)
                }
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                select
                InputLabelProps={{ shrink: true }}
                error={formik.touched.tool_id && Boolean(formik.errors.tool_id)}
                helperText={formik.touched.tool_id && formik.errors.tool_id}
                label="Tool"
                margin="none"
                value={formik.values.tool_id}
                variant="outlined"
                onChange={(event) =>
                  handleInputChange("tool_id", event.target.value)
                }
              >
                {tools.map((tool) => (
                  <MenuItem key={tool.id} value={tool.id}>
                    {tool.toolname}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
          <Grid container spacing={8}>
            <Grid container item justifyContent="center" xs={12}>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  select
                  InputLabelProps={{ shrink: true }}
                  SelectProps={{
                    multiple: true,
                    onChange: (event) =>
                      handleInputChange("tagids", event.target.value),
                    value: formik.values.tagids || [],
                  }}
                  error={formik.touched.tagids && Boolean(formik.errors.tagids)}
                  helperText={formik.touched.tagids && formik.errors.tagids}
                  label="Tags"
                  margin="none"
                  variant="outlined"
                >
                  {tags.map((tag) => (
                    <MenuItem key={tag.id} value={tag.id}>
                      {tag.tagname}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid container alignItems="center" justifyContent="center">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formik.values.isfromtypeform || false}
                      color="primary"
                      name="isfromtypeform"
                      onChange={(event) =>
                        handleInputChange(
                          "isfromtypeform",
                          event.target.checked
                        )
                      }
                    />
                  }
                  label="isFromTypeform"
                />
              </Grid>
            </Grid>
          </Grid>
          <Box display="flex" justifyContent="center" mt={2}>
            <Button
              color="primary"
              type="submit"
              variant="contained"
              onClick={formik.handleSubmit}
            >
              Submit
            </Button>
          </Box>
        </Grid>
      </Box>
    </>
  );
};

const PromptContainer = () => {
  const { fetchTools, fetchTags, updatePrompt, tags, tools } = useAppContext();
  const { id } = useParams();
  const history = useHistory();
  const location = useLocation();
  const [configurationEntity, setConfigurationEntity] = useState(null);
  const { rowData } = location.state || {};

  useEffect(() => {
    fetchTools();
    fetchTags();
  }, [fetchTags, fetchTools]);

  useEffect(() => {
    if (rowData && tags.length > 0) {
      const tagIds = rowData.tags.map(
        (tagName) => tags.find((tag) => tag.tagname === tagName)?.id
      ).filter(tagId => tagId);

      setConfigurationEntity({ ...rowData, id, tagids: tagIds });
    }
  }, [id, location.state, rowData, tags]);

  const validationSchema = Yup.object({
    description: Yup.string().required("Description is required"),
    isfromtypeform: Yup.boolean(),
    prompttext: Yup.string().required("Prompt is required"),
    tagids: Yup.array()
      .of(Yup.string())
      .required("At least one tag is required")
      .min(1, "At least one tag is required"),
    title: Yup.string().required("Title is required"),
    tool_id: Yup.string().required("Tool is required"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: configurationEntity || {
      description: "",
      isNew: true,
      isfromtypeform: false,
      prompttext: "",
      tagids: [],
      //tags: [],
      title: "",
      tool_id: null,
    },
    onSubmit: async (values) => {
      const selectedTags = tags.filter(tag => values.tagids.includes(tag.id)).map(tag => tag.tagname);

      const cleanedValues = {
        description: values.description,
        isfromtypeform: values.isfromtypeform,
        prompttext: values.prompttext,
        tagids: values.tagids,
        tags: selectedTags,
        title: values.title,
        toolid: values.tool_id,
        toolname: tools.find(tool => tool.id === values.tool_id)?.toolname || '',
      };
      if (values.isNew) {
        cleanedValues.isNew = values.isNew;
      }if (!values.isNew) {
        cleanedValues.id = values.id;
      }
  
      await updatePrompt(cleanedValues);
      history.push("/copilot/prompt");
    },
    validationSchema: validationSchema,
  });

  if (!formik.values) {
    return (
      <Box
        alignItems="center"
        display="flex"
        justifyContent="center"
        width="100%"
      >
        <Typography variant="subtitle1">Prompt is not found 😢</Typography>
      </Box>
    );
  }

  return <Prompt formik={formik} tags={tags} tools={tools} />;
};

export default PromptContainer;
